.container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-box {
    border-radius: 4px;
    color: #b4b4b7;
    height: fit-content;
    margin-bottom: 24px;
    margin-top: 2px;
    max-width: 400px;
    width: 100%;
}

.return-address {
    display: flex;
    align-items: center;
    color: rgb(149, 149, 149);
    height: 45px;
    background-color: rgb(43, 43, 43);
    margin-top: 20px;
    cursor: pointer;
}

.return-address > input {
    background-color: transparent;
    cursor: pointer;
    border-style: none;
    color: inherit;
    outline: none;
    text-align: center;
    width: 100%;
    padding-left: 20px;
}

.footer-buttons {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    justify-content: space-around;
    padding: 0 18px 8px;
}

.add-network {
    align-items: center;
    background-color: #ffffff12;
    border: 1px solid #28282852;
    border-radius: 6px;
    color: #a7a4a4;
    cursor: pointer;
    width: 200px;
    display: flex;
    font-size: 11px;
    justify-content: center;
    margin-top: 8px;
    padding: 4px 10px;
}

.add-network:hover {
    border: 1px solid #45b78fa8;
    background: #00000096;
    color: white;
}

@media screen and (max-width: 500px) {
    .footer-buttons {
        padding: 0px 20px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        gap: 0px;
    }

    .footer-buttons > button {
        margin-top: 6px;
        width: 70%;
    }
}